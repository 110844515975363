<template>
  <form
      class="validator-form"
      :class="{
        'form-pane--error': validator.$invalid,
       }"
      role="form"
      novalidate
      autocomplete="off"
      @submit.prevent.stop="submitHandler">
    <slot
        :failures="failures"
        :loading="loading"
        :success="success"
        :validator="validator" />

    <teleport to="#modal">
      <ModalContainer
          :open="!!generalError"
          @update:open="generalError = null">
        <DialogPanel
            :title="t('errorTitle')"
            :cancel="false"
            type="warning"
            @close="generalError = null"
            @confirm="generalError=null">
          <Paragraph>
            {{ generalError }}
          </Paragraph>
        </DialogPanel>
      </modalcontainer>
    </teleport>
  </form>
</template>
<i18n>
{
  "nl": {
    "errorTitle": "Fout",
    "error": "Er is iets misgegaan bij het verwerken van dit formulier. We gaan er naar kijken, probeer het laten nog eens."
  },
  "en": {
    "errorTitle": "Error",
    "error": "Something went wrong when processing this form. We will take a look, please try again later."
  }
}
</i18n>
<script>
import {
  computed,
  provide,
  ref,
  toRefs,
} from 'vue';
import * as Sentry from '@sentry/vue';
import { useI18n } from 'vue-i18n';
import ModalContainer from '../modal/ModalContainer.vue';
import DialogPanel from '../modal/DialogPanel.vue';
import Paragraph from '../typography/Paragraph.vue';
import { ValidationError } from '../../utils/vuelidate.js';

export default {
  components: { Paragraph, DialogPanel, ModalContainer },
  props: {
    validator: {
      type: Object,
      required: true,
    },
    submit: {
      type: [Function, Promise],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    externalResults: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['submit', 'error', 'success', 'update:external-results'],
  setup(props, { emit }) {
    const {
      submit, name, validator,
    } = toRefs(props);
    const loading = ref(false);
    const failures = ref([]);
    const success = ref(false);
    const generalError = ref(null);
    const formBaseName = performance.now() % 100000;

    const { t } = useI18n();

    provide('baseFieldName', computed(() => `form-${name.value || formBaseName}`));
    provide('withinForm', ref(true));

    return {
      t,

      loading,
      failures,
      success,

      generalError,

      async submitHandler() {
        if (!loading.value) {
          loading.value = true;
          await emit('submit');
          await validator.value.$validate();
          if (validator.value.$invalid) {
            // Non-external validation error
            await emit('error');
          } else {
            try {
              // Call submit handler and hope for the best
              await Promise.resolve(submit.value());

              validator.value.$reset();
              await emit('success');
              success.value = true;
              setTimeout(() => {
                success.value = false;
              }, 1000);
            } catch (e) {
              if (!(e instanceof ValidationError)) {
                generalError.value = t('error');
                if (import.meta.env.DEV) {
                  // eslint-disable-next-line no-console
                  console.error(e);
                }
                Sentry.captureException(e);
              } else {
                if (e.failures && e.failures.length > 0) {
                  failures.value = e.failures;
                }

                // Notify upstream that we had external results
                emit('update:external-results', e.state || {});
              }

              // ERROR
              await emit('error');
            }
          }
          loading.value = false;
        }

        return true;
      },
    };
  },
};
</script>
<style>

.validator-form {
  display: flex;
  flex-direction: column;

  min-width: 0;

  min-height: 0;

  &:only-child {
    flex: 1;
  }
}

</style>
