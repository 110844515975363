<template>
  <InputField
      :id="id"
      ref="input"
      :value="plainValue"
      :name="name"
      :min="min"
      :max="max"
      :step="step"
      :lang="locale"
      :size="size"
      :inputmode="(step < 1) ? 'decimal' : undefined"
      type="number"
      class="number-input"
      :class="{
        'number-input--read-only': readOnly,
      }"
      @input="handleInput($event.target.value, $event)" />
</template>

<script>
import {
  ref,
  toRefs,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import InputField from './Input.vue';

export default {
  components: { InputField },
  inheritAttrs: false,
  props: {
    ...InputField.props,
    modelValue: {
      type: Number,
      default: () => null,
    },
    min: {
      type: Number,
      default: () => null,
    },
    max: {
      type: Number,
      default: () => null,
    },
    step: {
      type: Number,
      default: () => 1,
    },
    size: {
      type: Number,
      default: () => null,
    },
    digits: {
      type: Number,
      default: () => null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    // refs
    const { modelValue } = toRefs(props);
    const error = ref(false);
    const input = ref(null);
    const plainValue = ref(modelValue.value);
    let intervalValue = modelValue.value;

    // uses
    const { locale } = useI18n();

    // computed

    // methods
    const handleInput = (value, ev) => {
      const v = Number.isNaN(ev.target.valueAsNumber) ? null : ev.target.valueAsNumber;
      const { valid } = ev.target.validity;
      error.value = !valid;

      if (v !== modelValue.value && valid) {
        intervalValue = v;
        emit('update:modelValue', v);
      }
    };

    // watchers
    watch(modelValue, (n) => {
      if (intervalValue !== n) {
        intervalValue = n;
        plainValue.value = n;
        // FORCE
        input.value.$el.value = n;
      }
    });

    return {
      input,
      plainValue,

      locale,
      error,

      handleInput,

      select() {
        input.value?.select();
      },
      focus() {
        input.value?.focus();
      },
      blur() {
        input.value?.blur();
      },
    };
  },
};
</script>
<style>
.number-input {
  padding: 0 var(--dimension-small);

  text-align: center;

  &:invalid {
    color: var(--color-error);
  }
}
</style>
