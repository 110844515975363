<template>
  <ValidatorForm
      :name="name"
      :validator="validator"
      :submit="submit"
      :external-results="externalResults"
      @success="$emit('success')"
      @errror="$emit('error')"
      @submit="$emit('submit')"
      @update:external-results="v => $emit('update:external-results', v)">
    <template #default="{success, loading: submitLoading, validator:v, failures}">
      <DialogActionPane :scrollable="scrollable">
        <slot
            name="content"
            :failures="failures"
            :loading="submitLoading"
            :success="success"
            :validator="v">
          <InsetPane>
            <FormMessagePane
                :validator="v"
                :failures="failures" />

            <slot
                :failures="failures"
                :loading="submitLoading"
                :success="success"
                :validator="v" />
          </InsetPane>
        </slot>

        <template #actions>
          <slot
              name="actions"
              :failures="failures"
              :loading="submitLoading"
              :success="success"
              :validator="v" />
        </template>

        <template #buttons>
          <slot
              name="submit-button"
              :loading="submitLoading"
              :requires-dirty="requiresDirty"
              :success="success"
              :failures="failures"
              :type="type"
              :validator="v">
            <FormSubmitButton
                :loading="submitLoading || loading"
                :requires-dirty="requiresDirty"
                :enabled="enabled"
                :success="success"
                :type="type"
                :validator="v">
              <slot
                  name="submit-label"
                  :failures="failures"
                  :loading="submitLoading || loading"
                  :success="success"
                  :validator="v" />
              <template #icon>
                <slot name="submit-icon" />
              </template>
            </FormSubmitButton>
          </slot>
        </template>
      </DialogActionPane>
    </template>
  </ValidatorForm>
</template>
<script>
import FormMessagePane from './FormMessagePane.vue';
import ValidatorForm from './ValidatorForm.vue';
import FormSubmitButton from './FormSubmitButton.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import InsetPane from '../panels/InsetPane.vue';

export default {
  components: {
    InsetPane,
    DialogActionPane,
    FormSubmitButton,
    ValidatorForm,
    FormMessagePane,
  },
  props: {
    ...FormSubmitButton.props,
    ...ValidatorForm.props,
    ...DialogActionPane.props,
  },
  emits: ['update:external-results', 'success', 'error', 'submit'],
};
</script>
