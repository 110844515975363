<template>
  <li>
    <router-link
        v-if="enabled"
        v-slot="{ navigate, href, isActive, isExactActive }"
        :to="to"
        :active-class="activeClass"
        :exact-active-class="exactActiveClass"
        :replace="replace"
        custom>
      <a
          class="navigation-tab"
          :class="{
          'navigation-tab--active': isActive,
          'navigation-tab--exact': isExactActive,
          'navigation-tab--sub-navigation': subNavigation,
          'navigation-tab--disabled': !enabled,
          'navigation-tab--children': !!$slots.children,
        }"
          :href="href"
          @click="navigate">
      <span
          v-if="!!$slots.icon"
          class="navigation-tab__icon">
        <slot name="icon" />
      </span>
        <slot :active="isActive">
      <span class="navigation-tab__label">
        <slot
            name="label"
            :active="isActive" />
      </span>
        </slot>
      </a>
      <div class="navigation-tab__children" v-if="!!$slots.children">
        <slot
            name="children"
            :active="isActive" />
      </div>
    </router-link>
    <span
        v-else
        class="navigation-tab"
        :class="{
          'navigation-tab--disabled': !enabled,
        }">
      <span
          v-if="!!$slots.icon"
          class="navigation-tab__icon">
        <slot name="icon" />
      </span>
      <slot>
      <span class="navigation-tab__label">
        <slot name="label" />
      </span>
      </slot>
    </span>
  </li>
</template>
<script>
import { RouterLink } from 'vue-router';

export default {
  props: {
    ...RouterLink.props,
    enabled: {
      type: Boolean,
      default: () => true,
    },
    subNavigation: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.navigation-tab {
  @media (any-hover: hover) {
    &:hover {
      background-color: rgba(var(--rgb-default-background), .25);
    }
  }
  display: flex;
  gap: var(--dimension-x-small);
  flex-direction: row;
  align-items: center;

  padding: var(--dimension-small) var(--dimension-small);

  color: inherit;
  background-color: #E2E2E2;
  background-color: color-mix(in srgb, var(--navigation-tabs-background-color), var(--color-default-foreground) 12.5%);
  border-left: 0 solid var(--color-primary-background);

  text-decoration: none;

  transition: border-left-width var(--animation-default-duration) linear, background-color var(--animation-default-duration);

  &__label {
    display: block;

    padding: var(--dimension-small) 0;

    font-weight: 500;
  }

  &--active {
    background-color: rgba(var(--rgb-default-background), .3);
    border-left: var(--dimension-x-small) solid rgba(var(--rgb-primary), .5);
  }

  &--sub-navigation {
    border-left: var(--dimension-x-small) solid rgba(var(--rgb-background), 1);
  }

  &--disabled {
    opacity: .25;
  }

  &--exact {
    background-color: var(--color-default-background);
    border-left: var(--dimension-x-small) solid var(--color-primary-background);

    font-weight: 500;
  }

  &__children {
    display: block;

    border-top: 1px solid var(--color-default-background);
  }
}
</style>
