<template>
  <SortableContainer
      :items="rows"
      :allowed="allowed"
      :content-type="contentType"
      :resolver="resolver"
      class="sortable-table-content"
      :context="context"
      @drop="(item, index) => $emit('drop', item, index)"
      @update:items="v => $emit('update:rows', v)">
    <template #default="{items: orderedRows, allowDrop}">
      <TableContent
          :id-field="idField"
          :columns="columns"
          :body-class="{'sortable-table-content__content--drop': allowDrop, 'sortable-table-content__content': true}"
          :row-animated="false"
          :rows="orderedRows">
        <template #default="defaultProps">
          <slot v-bind="defaultProps" />
        </template>
        <template #row="{row, ...rowProps}">
          <slot
              name="row"
              v-bind="{row, context, contentType, ...rowProps, dragEnabled: enabled}" />
        </template>
        <template #empty="emptyProps">
          <slot
              name="empty"
              v-bind="emptyProps" />
        </template>
        <template #header="headerProps">
          <TableHeader
              v-if="enabled && !!$slots.header"
              type="narrow" />
          <slot
              name="header"
              v-bind="headerProps" />
        </template>
        <template #footer="footerProps">
          <slot
              name="footer"
              v-bind="footerProps" />
        </template>
      </TableContent>
    </template>
  </SortableContainer>
</template>
<script>
import TableContent from './TableContent.vue';
import SortableContainer from '../draggable/SortableContainer.vue';
import DropContainer from '../draggable/DropContainer.vue';
import TableHeader from './TableHeader.vue';

export default {
  components: {
    TableHeader,
    SortableContainer,
    TableContent,
  },
  props: {
    ...DropContainer.props,
    ...TableContent.props,
    enabled: {
      type: Boolean,
      default: () => true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  emits: [...DropContainer.emits, 'update:rows'],
};
</script>
