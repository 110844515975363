<template>
  <router-link
      v-slot="{ navigate, isActive }"
      custom
      :to="to"
      class="navigation-table-row__wrapper list__item">
    <InteractiveTableRow
        v-bind="$attrs"
        class="navigation-table-row"
        :class="{'navigation-table-row--active': isActive}"
        @click="() => navigate()">
      <slot />
    </InteractiveTableRow>
  </router-link>
</template>
<script>

import InteractiveTableRow from './InteractiveTableRow.vue';

export default {
  components: { InteractiveTableRow },
  inheritAttrs: false,
  props: {
    to: {
      type: Object,
      default: () => null,
    },
  },

};
</script>
<style>
.navigation-table-row {
  @media (any-hover: hover) {
    &:hover {
      background-position: 0 100%;
      color: var(--color-default-foreground);
    }
  }
}
</style>
