<template>
  <TextLabel
      tag="span"
      class="start-span">
    {{ timeFormatter.format(start) }}
  </TextLabel>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { useTimeFormatter } from '../../utils/date.js';
import TextLabel from '../labels/TextLabel.vue';

export default {
  components: { TextLabel },
  props: {
    start: {
      type: Date,
      required: true,
    },
  },
  setup() {
    // refs

    // uses
    const { locale } = useI18n();
    const timeFormatter = useTimeFormatter(locale);

    return {
      timeFormatter,
    };
  },
};
</script>
<style>
.start-span {
  font-variant-numeric: tabular-nums;

  white-space: nowrap;
}
</style>
