<template>
  <InteractiveListItem
      class="selectable-list-item"
      v-bind="$attrs"
      tabindex="0"
      :class="{'selectable-list-item--selected': selected}"
      @keydown="handleKeyInput"
      @click="$emit('select')">
    <slot />
  </InteractiveListItem>
</template>
<script>

import InteractiveListItem from './InteractiveListItem.vue';

export default {
  components: { InteractiveListItem },
  inheritAttrs: false,
  props: {
    selected: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    return {
      handleKeyInput(ev) {
        if (ev.key === 'Enter') {
          ev.stopPropagation();
          ev.preventDefault();
          emit('select');
        }
      },
    };
  },
};
</script>
<style>
.selectable-list-item {
  &--selected {
    background-position: 0 67%;
  }

  &:focus-within {
    color: var(--color-white);
    background-position: 0 100%;
  }
}
</style>
