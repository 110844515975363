<template>
  <div class="time-duration-span">
    <StartSpan
        v-if="duration.start"
        :start="duration.start"
        class="time-duration-span__start" />
    <StartSpan
        v-if="duration.end && showEnd"
        class="time-duration-span__end"
        :start="duration.end" />
  </div>
</template>
<script>
import StartSpan from './StartSpan.vue';

export default {
  components: { StartSpan },
  props: {
    duration: {
      type: Object,
      required: true,
    },
    showEnd: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.time-duration-span {
  display: flex;
  gap: var(--dimension-xx-small);
  flex-direction: column;
  align-self: flex-end;

  &__end {
    opacity: .5;
  }
}
</style>
