<template>
  <router-link
      v-slot="{ href, navigate, isActive }"
      custom
      :to="to"
      class="navigation-list-item__wrapper list__item">
    <InteractiveListItem
        v-bind="$attrs"
        class="navigation-list-item"
        :class="{'navigation-list-item--active': isActive}">
      <a
          :href="href"
          class="navigation-list-item__link"
          @click="navigate">
        <slot />
      </a>
    </InteractiveListItem>
  </router-link>
</template>
<script>

import InteractiveListItem from './InteractiveListItem.vue';

export default {
  components: { InteractiveListItem },
  inheritAttrs: false,
  props: {
    to: {
      type: Object,
      default: () => null,
    },
  },

};
</script>
<style>
.navigation-list-item {
  padding: 0;

  @media (any-hover: hover) {
    &:hover {
      background-position: 0 100%;
    }
  }

  &__link {
    display: block;
    flex: 1;

    padding: var(--dimension-small);

    color: currentColor;

    text-decoration: none;

  }
}
</style>
