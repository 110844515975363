<template>
  <InputField
      :value="timeValue"
      v-bind="$attrs"
      :min="minValue"
      :max="maxValue"
      type="time"
      class="time-input"
      @input="handleInput($event.target.value)" />
</template>

<script>
import {
  format,
  parse,
} from 'date-fns';
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import InputField from './Input.vue';

export default {
  components: { InputField },
  inheritAttrs: false,
  props: {
    ...InputField.props,
    modelValue: {
      type: Date,
      default: () => null,
    },
    min: {
      type: Date,
      default: () => null,
    },
    max: {
      type: Date,
      default: () => null,
    },
    formats: {
      type: Array,
      default: () => [
        'yyyy-MM-dd',
        'dd-MM-yyyy',
      ],
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const {
      modelValue, validator, readOnly, min, max,
    } = toRefs(props);
    const error = ref(false);

    const formatter = (d) => format(d, 'HH:mm');

    return {
      error,
      timeValue: computed(() => ((modelValue.value) ? formatter(modelValue.value) : '')),
      classes: computed(() => ({
        'time-input--error': error.value || validator.value?.$error,
        'time-input--read-only': readOnly.value,
      })),
      maxValue: computed(() => ((max.value) ? formatter(max.value) : '')),
      minValue: computed(() => ((min.value) ? formatter(min.value) : '')),
      handleInput(value) {
        // First we need to check if we have a year, since parse is okay with 2-digit
        // years, and it screws up the parsing.
        if (value.split(':').filter((p) => p.length === 2).length < 2) {
          error.value = true;
          emit('update:model-value', null);
        } else {
          // Parse all the formats
          const parsedValue = parse(value, 'HH:mm', new Date());
          error.value = !parsedValue;
          emit('update:model-value', (error.value) ? null : parsedValue);
        }
      },
    };
  },
};
</script>
